<template>
  <base-section id="features" class="secondary">
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <base-info-card
              align="center"
              dark
              icon="mdi-monitor-cellphone"
              :title="$ml.get('convenient_access')"
              :text="$ml.get('convenient_access_text')"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <base-info-card
              align="center"
              dark
              icon="mdi-chart-line"
              :title="$ml.get('detailed_statistics')"
              :text="$ml.get('detailed_statistics_text')"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <base-info-card
              align="center"
              dark
              icon="mdi-sitemap"
              :title="$ml.get('data_protection')"
              :text="$ml.get('data_protection_text')"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <base-info-card
              align="center"
              dark
              icon="mdi-puzzle-outline"
              :title="$ml.get('support247')"
              :text="$ml.get('support247_text')"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "SectionFeatures",

  data: () => ({   
  }),
};
</script>
